import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import BehindBars from "../components/BehindBars/BehindBars";
import BlockContent from "../components/BlockContent";
import BlockLottery from "../components/BlockLottery";
import BlockImage from "../components/BlockImage";
import BlockIndex from "../components/BlockIndex";
import Hyperlink from "../components/Hyperlink";
import { getImageUrl, getCountry } from "../api/sanity";
import { getImageSrcSet } from "../helper/image";
import { GlobalContext } from "../globalStates/globalData";
import { LanguageContext } from "../globalStates/Language";
import useIsMobile from "../globalStates/isMobile";
import twitter from '../images/Twitter-red.svg';
import './country.css';


export default function Country() {

	const [data, setData] = useState(null);
	const { globalData } = useContext(GlobalContext);
	const { language, translation } = useContext(LanguageContext);
	const { isMobile } = useIsMobile();
	const { slug } = useParams();

	useEffect(() => {
		if (slug) {
			getCountry(slug, language)
				// .then(r => { console.log('country', r); return r; })
				.then(setData)
				.catch(console.error);
		}
	}, [slug, language]);

	let next = null;
	if (globalData?.countries?.length > 1) {
		let i = globalData.countries.findIndex((j) => { return j.slug === slug });
		if (i > -1) {
			++i;
			if (i === globalData.countries.length) i = 0;
			next = globalData.countries[i];
		}
	}

	if (!data) return <main id="main" className="single-country"></main>;

	return <main id="main" className="single-country">
		<BehindBars />
		<div className="back-and-forth">
			{
				next &&
				<Hyperlink to={"/country/" + next.slug} className="forth" >{translation.country[next.country]} &gt;</Hyperlink>
			}
			<Hyperlink to="/#countries" className="back" >&lt; {translation.singleCountry.back}</Hyperlink>
		</div>

		<div className="article-inner">

			{
				isMobile && data.mainImage &&
				<div className="p-relative">
					<div className="article-main-image">
						<img
							src={getImageUrl(data.mainImage).width(768).url()}
							srcSet={getImageSrcSet(data.mainImage)}
							sizes='100vw'
							alt="" />
					</div>
					{
						data.caption && <p className="article-main-image-caption">
							{data.caption}
						</p>
					}
				</div>
			}

			<div className="article-top">
				{
					data.country &&
					<div className="article-top__left">
						<p>{translation.singleCountry.country}</p>
						<h2>{translation.country[data.country]}</h2>
					</div>
				}

				<div className="article-top__middle">
				</div>

				{
					data.link && data.link.link &&
					<div className="article-top__right">
						<p>{data.link.title}</p>
						<a className="article-top__right-button ttw-button" href={data.link.link} target={data.link.target} rel="noopener noreferrer">
							{translation.singleCountry.discover}
							<img className="article-top__right-button-icon" src={twitter} alt="Twitter Logo" />
						</a>
					</div>
				}

			</div>

			{
				!isMobile && data.mainImage &&
				<div className="p-relative">
					<div className="article-main-image">
						<img
							src={getImageUrl(data.mainImage).width(1440).url()}
							srcSet={getImageSrcSet(data.mainImage)}
							sizes='80vw'
							alt="" />
					</div>
					{
						data.caption && <p className="article-main-image-caption">
							{data.caption}
						</p>
					}
				</div>

			}

			{
				data.excerpt && data.excerpt.map((block) => {
					return <BlockContent className="article-excerpt" block={block} key={block._key} />
				})
			}

			{
				data.quote &&
				<h2 className="article-quote">{data.quote}</h2>
			}

			<div className="article-blocks">
				{
					data.body && data.body.map((block) => {

						switch(block._type){
							case 'block': return <BlockContent className="article-blocks__block" block={block} key={block._key} />
							case 'image': return <BlockImage image={block} key={block._key} />
							case 'blockContentInsert':
								switch(block.insert){
									case 'lottery': return <BlockLottery country={data.country} key={block._key} />
									case 'index': return <BlockIndex index={data.index} country={data.country} key={block._key} />
									default: return null;
								}
							default: return null;
						}
					})
				}
			</div>

		</div>
	</main>;
}