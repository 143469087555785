import React, { useContext, useState } from "react";
import { LanguageContext } from "../../globalStates/Language";
import paypal from "../../images/PayPal.svg";

const business = "sj@reporter-ohne-grenzen.de";
const defaultAmount = ['10', '25', '50'];

export default function TextImageDonate() {

	const [amount, setAmount] = useState('10');
	const [input, setInput] = useState('');
	const { translation } = useContext(LanguageContext);

	const onInputChange = (event) => {
		let value = event.target.value;
		if(value && Number(value) < 0) value = "0";
		else {
			let i = value.indexOf('.');
			if(i !== -1 && i < value.length - 3){
				value = value.slice(0, ( i - value.length + 3));
			}
		}
		setAmount(value);
		setInput(value);
	}
	const onClickButton = (amount) => {
		setAmount(amount);
		setInput('');
	}

	return <div className="section-text-image__donate-suggestions">
		{
			defaultAmount.map(deAmount => {
				let className = amount === deAmount ? ' active' : '';
				return <button className={"section-text-image__amount ttw-button" + className} onClick={() => onClickButton(deAmount)} key={deAmount}>
					<h5>{deAmount} €</h5></button>;
			})
		}
		<input 
			className="section-text-image__amount ttw-button" 
			type="number" 
			onChange={onInputChange}
			value={input} 
			min="0"
			placeholder={translation.textImage.different}/>

		<form className={"section-text-image__donate-form"} action="https://www.paypal.com/donate" method="post" target="_blank">
			{/* Identify your business so that you can collect the payments. */}
			<input type="hidden" name="business" value={business} />
			{/* Specify details about the contribution */}
			{amount && <input type="hidden" name="amount" value={amount} />}
			<input type="hidden" name="no_recurring" value="0" />
			<input type="hidden" name="item_name" value="" />
			<input type="hidden" name="item_number" value="The Truth Wins" />
			<input type="hidden" name="currency_code" value="EUR" />

			{/* Display the payment button. */}
			<input className="section-text-image__donate ttw-button" 
				type="submit" 
				name="submit" 
				value={translation.textImage.donate.before + amount + translation.textImage.donate.after} />

		</form>
		<img className="section-text-image__donate-paypal" src={paypal} alt="" />
	</div>
}