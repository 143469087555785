import React, { useContext, useRef, useState } from "react";
import gsap from "gsap";
import HeroOverlay from "./HeroOverlay";
import HeroYoutube from "./HeroYoutube";
import { getFileUrl, getImageUrl } from "../../api/sanity";
import { LanguageContext } from "../../globalStates/Language";
import useUpdateNav from "../../globalStates/updateNav";
import { getImageSrcSet } from "../../helper/image";
import RSFLogo from "../../helper/RSFLogo";
import './hero.css';

export default function Hero(props) {
	// console.log('Hero', props);

	const [heroClass, setHeroClass] = useState('');
	const { language, translation } = useContext(LanguageContext);
	const content = props.content || {};
	const video = useRef(null);

	const { updateNav } = useUpdateNav();

	let playCursor = !(!content.youtube && !content.vimeo && !content.video);

	/** Enables scrolling */
	const enableScroll = () => {
		document.body.classList.remove('no-scroll', 'hide-menu');
		document.documentElement.classList.remove('no-scroll');
	}
	/** Disables scrolling */
	const disableScroll = () => {
		document.body.classList.add('no-scroll', 'hide-menu');
		document.documentElement.classList.add('no-scroll');
	}

	/** Start/stop playing youtube video */
	const _onClick = (event) => {

		if (heroClass) {
			closeFullscreen();
			document.dispatchEvent(new Event('hero:stop-video'));

		} else {
			setHeroClass('fullscreen');
			document.dispatchEvent(new Event('hero:start-video'));

			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			if (scrollTop !== 0) {
				gsap.to(window, { duration: 0.5, scrollTo: 0, ease: "sine.inOut", onComplete: disableScroll });
			} else {
				disableScroll();
			}
			video?.current.pause();
			return true;
		}
	}

	const closeFullscreen = () => {
		setHeroClass('');
		enableScroll();
		video?.current.play();
	}

	const rsfLogo = RSFLogo(language);
	
	return <section id={content.anchor} className={"section-hero mode-dark " + heroClass} ref={updateNav}>

		<HeroYoutube embedVideo={content.embedVideo} closeFullscreen={closeFullscreen} />

		<video
			className="section-hero__video"
			src={getFileUrl(content.video)}
			poster={getImageUrl(content.poster).width(1440).url()}
			ref={video}
			autoPlay
			muted
			loop
			playsInline
		/>
		
		<div className="section-hero__inner">
			{
				content.logo &&
				<img
					className="section-hero__logo"
					src={getImageUrl(content.logo).width(768).url()}
					srcSet={getImageSrcSet(content.logo)}
					sizes="(min-width: 992px) 35w, (min-width: 768px) 70w, 100w"
					alt="" />
			}
			{
				content.description &&
				<p className="section-hero__description">{content.description}</p>
			}
		</div>

		<div className="section-hero__scroll">
			<span className="rotate-90">
				&lt; {translation.hero.scroll}
			</span>
		</div>
		<img className="section-hero__logo-small" src={rsfLogo} alt="" />

		{
			playCursor &&
			<HeroOverlay onClick={_onClick} heroClass={heroClass} />
		}
	</section>
}