import React from "react";
import Hyperlink from "./Hyperlink";

export default function BlockContent ({ block, className }) {

	if(!block && block.style) return null;

	const markDefs = {};
	block.markDefs.forEach(mark => {
		markDefs[mark._key] = mark;
	});


	const getContent = () => {
		if( block.children.length === 1 && !block.children[0].marks.length ) return block.children[0].text;

		let result = [];

		block.children.forEach((child, index) => {
			let text = child.text;
			if(!child.marks.length) return result.push(<span key={index}>{text}</span>);
			
			let param = {key: index};
			child.marks.forEach(mark => {
				switch(mark){
					case 'strong': 
						text = <strong {...param}>{text}</strong>;
						param = {};
						break;
					case 'em': 
						text = <em {...param}>{text}</em>;
						param = {};
						break;
					default:
						if(markDefs[mark] && markDefs[mark].href){
							text = <Hyperlink to={markDefs[mark].href} {...param}>{text}</Hyperlink>
						} else {
							text = <span {...param}>{text}</span>;
						}
						param = {};
				}
			});
			result.push(text);
		});

		return result;
	}

	switch(block.style){
		case 'h1': return <h1 className={className}>{getContent()}</h1>;
		case 'h2': return <h2 className={className}>{getContent()}</h2>;
		case 'h3': return <h3 className={className}>{getContent()}</h3>;
		case 'h4': return <h4 className={className}>{getContent()}</h4>;
		case 'h5': return <h5 className={className}>{getContent()}</h5>;
		case 'li': return <li className={className}>{getContent()}</li>;
		default: return <p className={className}>{getContent()}</p>;
	}
}