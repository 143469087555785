import sanityClient from '@sanity/client';
import ImageUrlBuilder from '@sanity/image-url';

const config = {
	projectId: '5o0v6vuw',
	dataset: 'production',
	apiVersion: '2022-01-27',
	useCdn: true
}
const sanity = sanityClient(config);

/**
 * Gets the homepage content
 * @param {string} lang 
 * @returns {Promise<any>}
 */
export const getHomePage = (lang = 'en') => {
	let query = `*[_type == "page" && slug.current == "/" && lang == "${lang}"][0].content`;
	// console.log('query > getHomePage:', query);
	return sanity.fetch(query);
}

/**
 * Gets a single country by slug
 * @param {string} slug 
 * @param {string} lang 
 * @returns {Promise<any>}
 */
export const getCountry = (slug, lang = 'en') => {
	let query = `*[_type == "country" && slug.current == "${slug}" && lang=="${lang}"][0]`;
	// console.log('query > getCountry:', query);
	return sanity.fetch(query);
}

/**
 * Gets the homepage content
 * @param {string} lang 
 * @returns {Promise<any>}
 */
export const getPageBySlug = (slug, lang = 'en') => {
	let query = `*[_type == "page" && slug.current == "${slug}" && lang == "${lang}"][0].body`;
	// console.log('query > getPageBySlut:', query);
	return sanity.fetch(query);
}

/**
 * Get header, footer and countries information
 * @param {string} lang 
 * @returns 
 */
export const loadDefaultData = (lang) => {
	let ids = { en: 'En', de: 'De' };
	let idLang = ids[lang];
	let query = `{
		'header': *[_id == 'header${idLang}'][0],
		'footer': *[_id == 'footer${idLang}'][0],
		'lottery': *[_id == 'lotteryResults'][0],
		'countries': *[_type == "country" && lang == "${lang}"]{ _id, "slug": slug.current, country, listImage} | order(ordered),
		'twitter': *[_type == "twitter"]{ _id, country, link, image }
	}`;
	// console.log('query > laodDefaultData:', query);
	return sanity.fetch(query);
}

/**
 * Gets the url to download the file
 * @param {*} source 
 * @returns {string | null}
 */
export const getFileUrl = (source) => {
	if (!source || !source.asset || !source.asset._ref) return '';

	const [_, id, extension] = source.asset._ref.split('-');
	if (_ !== 'file' || !id || !extension) return '#';

	return `https://cdn.sanity.io/files/${config.projectId}/${config.dataset}/${id}.${extension}?dl=`;
}

const imageBuilder = ImageUrlBuilder(sanity);

/**
 * Gets the url for the image
 * @param {*} source 
 * @returns {string | null}
 */
export const getImageUrl = (source) => {
	return imageBuilder.image(source);
}