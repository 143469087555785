
/**
 * For scrolling in x direction
 * @param {*} list 
 * @returns 
 */
export default function scrollX (list) {
	if(!list) return;

	let isDown = false;
	let startX = 0;
	let scrollLeft = 0;
	let isClick = true;

	const startDragging = event => {
		isDown = true;
		isClick = true;
		startX = event.pageX - list.offsetLeft;
		scrollLeft = list.scrollLeft;
	}

	const stopDragging = event => {
		isDown = false;
	}

	const dragging = event => {
		if(!isDown) return;

		event.preventDefault();
		const x = event.pageX - list.offsetLeft - startX;
		list.scrollLeft = scrollLeft - x;
		isClick = false;
	}

	const clickListener = event => {
		if(!isClick) {
			event.preventDefault();
			event.isDragged = true;
		}
	}

	const removeEventListener = () => {
		list.removeEventListener('mousedown', startDragging);
		list.removeEventListener('mouseup', stopDragging);
		list.removeEventListener('mouseleave', stopDragging);
		list.removeEventListener('mousemove', dragging);
		list.removeEventListener('click', clickListener, true);
	}

	list.addEventListener('mousedown', startDragging);
	list.addEventListener('mouseup', stopDragging);
	list.addEventListener('mouseleave', stopDragging);
	list.addEventListener('mousemove', dragging);
	list.addEventListener('click', clickListener, true);

	return removeEventListener;
}