import { useEffect } from "react";
import useIsMobile from "../globalStates/isMobile";
import useIsTablet from "../globalStates/isTablet";

export default function ResizeDetector() {
	const { isMobile, setIsMobile } = useIsMobile();
	const { isTablet, setIsTablet } = useIsTablet();

	useEffect(() => {
		function handleResize(event) {
			let newValue = window.innerWidth < 768;
			if (isMobile !== newValue) setIsMobile(newValue);

			newValue = window.innerWidth < 992;
			if (isTablet !== newValue) setIsTablet(newValue);
		}

		window.addEventListener('resize', handleResize);

		return (() => {
			window.removeEventListener('resize', handleResize);
		});
	}, [setIsMobile, isMobile, isTablet, setIsTablet]);

	return null;
}