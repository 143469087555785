import React, { useContext, useRef } from "react";
import { GlobalContext } from "../../globalStates/globalData";
import { LanguageContext } from "../../globalStates/Language";
import useIsTablet from "../../globalStates/isTablet";

import TTWLogo from "../../helper/TTWLogo";
import twitterSvg from "../../images/Twitter-white.svg";
import xIcon from '../../images/X-black.svg';

const order = {ru: 0, tr: 1, br:2};

export default function HeaderDiscover() {
	const { globalData } = useContext(GlobalContext);
	const { translation } = useContext(LanguageContext);
	const twitter = globalData?.twitter;
	const { isTablet } = useIsTablet();
	const listRef = useRef();

	function openMenu() {
		if (!listRef?.current) return;

		listRef.current.classList.add('open');
		if( !isTablet ) {
			window.addEventListener('scroll', closeMenu);
		} else {
			document.body.classList.add('no-scroll');
			document.documentElement.classList.add('no-scroll');
		}
	}

	function closeMenu() {
		if (!listRef?.current) return;

		listRef.current.classList.remove('open');
		if( !isTablet ) {
			window.removeEventListener('scroll', closeMenu);
		} else {
			document.body.classList.remove('no-scroll');
			document.documentElement.classList.remove('no-scroll');
		}
	}

	function sortCountries(a, b){
		let o = order[a.country] - order[b.country];
		if(o === 0 && a.link && a.link.title && b.link && b.link.title) {
			if(a.link.title > b.link.title) return -1;
			if(a.link.title < b.link.title) return 1;
			return 0;
		}
		return o;
	}

	if (!twitter) return null;

	twitter.sort(sortCountries);

	const discover = {};
	twitter.forEach(twit => {
		if(twit.country && twit.link && twit.link.title){
			if(!discover[twit.country]) discover[twit.country] = { country: twit.country, links: []};
			discover[twit.country].links.push(twit.link);
		}
	});

	return <div className="header-discover mode-dark">
		<button className="header-discover__menu-open" onClick={openMenu}>
			<p>{translation.discover.button}</p>
			<img src={twitterSvg} alt="" />
		</button>

		<div className="header-discover__menu" ref={listRef} >

			<div className="header-discover__menu-top">
				<p>{translation.discover.heading}</p>
				<button className="header-discover__menu-close" onClick={closeMenu}>
					<p>{translation.discover.close}</p>
					<img src={xIcon} alt="" />
				</button>
			</div>

			<ul className="header-discover__list">
				{
					Object.keys(discover).map((key, index) => {
						let entry = discover[key];
						return <li key={index}>
							{
								<>
									<div className="header-discover__dot" key="country">
										<img className="header-discover__dot-icon" src={TTWLogo(entry.country)} alt="" />
										<h3 className="header-discover__country">{ translation.country[entry.country] }</h3>
									</div>
									{
										entry.links.map((link, i)=>{
											let linkPorps = !link.target || link.target === '_blank' ? {rel: 'noopener noreferrer'} : {}; 
											return <a className="header-discover__link" href={link.link} target={link.target} key={i} {...linkPorps}>
												{link.title === 'Twitter' ? translation.discover.twitter : translation.discover.default}
											</a>
										})
									}
								</>
							}
						</li>
					})
				}
			</ul>
		</div>

		<div className="header-discover__back-overlay" onClick={closeMenu}></div>
	</div>
}