import React, { useContext, useRef } from "react";
import HeaderLanguage from "./HeaderLanguage";
import { GlobalContext } from "../../globalStates/globalData";
import xIcon from '../../images/X.svg';
import Hyperlink from "../Hyperlink";

export default function HeaderMobileMenu() {

	const { globalData } = useContext(GlobalContext);
	const content = globalData?.header;

	const ref = useRef();

	function closeMenu() {
		ref?.current?.classList.remove('open');
	}

	if (!content?.menu) return null;

	return (
		<nav id="header-mobile-menu" className="header-mobile__menu mode-dark" ref={ref}>
			<div className="header-mobile__top">
				<HeaderLanguage className="header-mobile__lang" divider="|" onClick={closeMenu} />
				<button className="header-mobile__close" onClick={closeMenu}>
					<img src={xIcon} alt="" />
				</button>
			</div>
			<ul>
				{
					content.menu.map((link) => {
						if (!link.title || !link.link) return null;

						return <li key={link._key} onClick={closeMenu}>
							<Hyperlink className="p" to={link.link} target={link.target} state={{ hashCheck: true }}>{link.title}</Hyperlink>
						</li>
					})
				}
			</ul>
		</nav>
	)
}