import React, { useState, createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import setHeat from "../helper/head";
import translations from "../local/translations";

const LanguageContext = createContext(null);

const defaultLanguage = 'en';
const allLanguages = ['de', 'en'];


function LanguageProvider(props) {
	const [ language, setLanguage] = useState(null);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!pathname) return;

		let lang = defaultLanguage;
		let path = pathname.split('/');

		if (path.length > 1 && path[1].length === 2 && allLanguages.includes(path[1])) {

			lang = path[1];

			// Seting language, alternative, canonical
			setHeat(path.slice(2).join('/'), lang, allLanguages, defaultLanguage);

			setLanguage(lang);
		} else {

			let userLang = navigator.language || navigator.userLanguage;
			if (userLang && userLang === 'de-DE') {
				navigate('/de' + path.join('/'));
			} else {
				navigate('/en' + path.join('/'));
			}
		}
	}, [pathname, navigate]);

	if (!language) return null;

	const languagePath = '/' + language;
	const translation = translations[language];

	return <LanguageContext.Provider value={{ language, setLanguage, languagePath, translation }}>
		{props.children}
	</LanguageContext.Provider>
}

export {
	LanguageContext,
	LanguageProvider
}
