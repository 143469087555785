import React, { useContext, useEffect, useRef, useState } from "react";
import Hyperlink from "./Hyperlink";
import './cookieBanner.css';
import { LanguageContext } from "../globalStates/Language";

const cookieName = "ttw_consent";

export default function CookieBanner() {
	const [show, setShow] = useState(false);
	const { translation } = useContext(LanguageContext);
	const banner = useRef(null);

	useEffect(() => {

		const onResize = () => {
			if(!banner) return;
			
			var top = window.innerHeight;
			console.log('resize', top);
			banner.current.style.top = top + 'px';
		}

		let name = cookieName + "=";
		const cookie = document.cookie
			.split('; ')
			.find(row => row.startsWith(name));

		if (!cookie) {
			let dnt = navigator.doNotTrack || window.doNotTrack;
			if (!dnt || (dnt !== "1" && dnt !== 1 && dnt !== "yes" )) {
				setShow(true);
				window.addEventListener('resize', onResize);
				onResize();
			}
		}

		return () => {
			window.removeEventListener('resize', onResize);
		}
	}, [banner]);

	const onAccept = () => {
		if (window._paq) {
			window._paq.push(['rememberConsentGiven']);
			setCookie('true');
			setShow(false);
		}
	}

	const onDecline = () => {
		setCookie('false');
		setShow(false);
	}

	const setCookie = (value) => {
		let d = new Date();
		d.setFullYear(d.getFullYear() + 1);
		document.cookie = cookieName + "=" + value + "; expires=" + d.toUTCString() + "; path=/";
	}

	return <div id="cookie-banner" className={show ? 'show' : 'hide'} ref={banner}>
		<div id="cookie-banner__inner">
			<p id="cookie-banner__message" >
				<span>{translation.cookie.message}</span>
				<Hyperlink to="/privacy">{translation.cookie.privacy}</Hyperlink>
			</p>
			<div id="cookie-banner__buttons">
				<button id="cookie-banner__accept" onClick={onAccept} >{translation.cookie.accept}</button>
				<button id="cookie-banner__decline" onClick={onDecline} >{translation.cookie.decline}</button>
			</div>
		</div>
	</div>
}