import React, { useContext } from "react";
import BehindBars from "./BehindBars/BehindBars";
import Hyperlink from "./Hyperlink";
import RSFLogo from "../helper/RSFLogo";
import gsap from "gsap";
import { GlobalContext } from "../globalStates/globalData";
import { LanguageContext } from "../globalStates/Language";
import './footer.css';

export default function Footer() {

	const { globalData } = useContext(GlobalContext);
	const { language, translation } = useContext(LanguageContext);
	const content = globalData?.footer;

	const backToTop = () => {
		gsap.to(window, { duration: 1.5, scrollTo: 0, ease: "sine.inOut" });
	}

	const getPhone = (phone) => {
		let tel = phone.replace(/[^\d+]/g,'');
		return <a href={"tel:" + tel}>{phone}</a>
	}

	const logo = RSFLogo(language);

	if (!content) return null;

	return <footer id="footer" className="mode-dark">
		<BehindBars />
		<div className="footer-inner">
			{
				content.heading &&
				<h2 className="footer-heading">{content.heading}</h2>
			}

			<div className="footer-row">

				<div  className="footer-logo">
					<img src={logo} width="152" height="40" alt="RSF Logo" />
				</div>

				{
					content.first &&
					<div className="footer-first">
						<p className="footer-first-text">
							{content.first}
							{
								content.phone && getPhone(content.phone)
							}
							{
								content.email &&
								<a href={"mailto:" + content.email}>{content.email}</a>
							}

						</p>
					</div>
				}

				{
					content.second &&
					<div className="footer-second">
						<p className="footer-second-text">{content.second}</p>

						{
							content.menu &&
							<nav className="footer-menu">
								<ul>
									{
										content.menu.map((link) => {
											if (!link.title || !link.link) return null;

											return <li key={link._key}>
												<Hyperlink className="p" to={link.link} target={link.target}>{link.title}</Hyperlink>
											</li>
										})
									}
								</ul>
							</nav>
						}

						{
							content.copyRight &&
							<p className="footer-copy-right">{content.copyRight}</p>
						}
					</div>
				}

			</div>
		</div>

		<button className="footer-back-to-top p" onClick={backToTop}>
			{translation.footer.top} &gt;
		</button>
	</footer>
}