import React from 'react';
import ball1 from '../../images/ball_01.svg';
import ball2 from '../../images/ball_02.svg';
import ball3 from '../../images/ball_03.svg';
import ball4 from '../../images/ball_04.svg';
import ball5 from '../../images/ball_05.svg';
import ball6 from '../../images/ball_06.svg';

const balls = [ball1, ball2, ball3, ball4, ball5, ball6];

export default function LotteryBall ({index}){
	if(index < 0 || index > balls.length - 1) return null; 
	return <img src={balls[index]} alt="" />;
}