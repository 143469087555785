import React, { useState, useEffect, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import { getHomePage } from "../api/sanity";
import Hero from "../components/Hero/Hero";
import List from "../components/List/List";
import Lottery from "../components/Lottery/Lottery";
import TextImage from "../components/TextImage/TextImage";

import { LanguageContext } from "../globalStates/Language";
import useIsMobile from "../globalStates/isMobile";
import useUpdateNav from "../globalStates/updateNav";
import animatedText from "../animations/textAnimationHandler";

import { gsap } from "gsap";
import CustomEase from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);


const scrollEase = CustomEase.create("custom", "M0,0,C0,0,0.35,0.4,0.55,0.7,0.75,1,0.85,1,1,1");
const codeToKey = { '33': 'PageUp', '34': 'PageDown', '35': 'End', '36': 'Home', '38': 'ArrowUp', '40': 'ArrowDown' };
let scroll = { is: false, index: 0, fired: 0, isPad: false, lastDelta: 0, lastTime: 0, target: null };
let timeoutId;
let timeOutHashLink = 300;

// let ttt = Date.now();


export default function Home() {
	const [data, setData] = useState(null);
	const { state } = useLocation();
	const { isMobile } = useIsMobile();
	const { nav } = useUpdateNav();
	const { language } = useContext(LanguageContext);


	const scrollToHash = useMemo(
		() => () => {
			let hash = window.location.hash;
			if (!hash) return null;
			if (hash[0] === '#') hash = hash.substring(1);

			let elem = document.getElementById(hash);
			if (elem) {
				scroll.is = true;
				
				// console.log('start scrolling', (Date.now() - ttt));
				return gsap.to(window, { duration: 1.5, scrollTo: elem, ease: "sine.inOut", onComplete: onScrollingEnd });
			}
		},
		[]
	)

	const onScrollingEnd = () => {
		if (scroll.fired > 10) {
			scroll.isPad = true;
			if (timeoutId) clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				scroll.isPad = false;
				scroll.fired = 0;
			}, 600);
		} else {
			scroll.fired = 0;
		}
		scroll.is = false;
	}


	// Gets page content	
	useEffect(() => {
		let animation;
		getHomePage(language)
			// .then(r => { console.log('home', r); return r; })
			.then(setData)
			.then(res => { setTimeout(() => { animation = scrollToHash(); }, timeOutHashLink); })
			.catch(console.error);

		return () => {
			if (typeof animation?.kill === 'function') animation.kill();
		};
	}, [scrollToHash, language]);


	// For scroll in y direction animation
	useEffect(() => {
		if (isMobile) return;

		if (!('IntersectionObserver' in window) ||
			!('IntersectionObserverEntry' in window) ||
			!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
			return;
		}

		let animation, sections;
		let lastX = 0, lastY = 0;


		/**
		 * Checks if scroll is allowed
		 */
		const noScrolling = () => {
			return document.body.classList.contains('no-scroll');
		}


		/**
		 * Scrolls down on section
		 */
		const moveDown = () => {
			if (noScrolling()) return;
			if (scroll.index < sections.length - 1) {
				scroll.is = true;
				let i = scroll.index + 1;
				animation = gsap.to(window, { duration: 1, scrollTo: sections[i], ease: scrollEase, onComplete: onScrollingEnd });
			}
		}


		/**
		 * Scrolls up on section
		 */
		const moveUp = () => {
			if (noScrolling()) return;
			if (scroll.index > 0) {
				scroll.is = true;
				let i = scroll.index - 1;
				let scrollTo = window.pageYOffset || document.documentElement.scrollTop;
				scrollTo += sections[i].getBoundingClientRect().bottom;
				scrollTo -= window.innerHeight;
				animation = gsap.to(window, { duration: 1, scrollTo, onComplete: onScrollingEnd });
			}
		}



		const resetScroll = () => {
			scroll.isPad = false;
			scroll.fired = 0;
			clearTimeout(timeoutId);
		}

		/**
		 * Handles all wheel events
		 */
		const wheelScrollHandler = (event) => {
			if(event.ctrlKey === true) return;

			event.preventDefault();

			let dt = event.timeStamp - scroll.lastTime;
			scroll.lastTime = event.timeStamp;

			if (scroll.isPad) {
				if (dt > 20) return;	// ignore all other dt, because they can deviate too much 

				if (event.deltaY > 0) {
					if (scroll.lastDelta + 10 < event.deltaY) resetScroll();
				} else {
					if (scroll.lastDelta - 10 > event.deltaY) resetScroll();
				}
			}

			if (!scroll.is && !scroll.isPad) {

				if (event.deltaY > 0) {
					moveDown();
				} else {
					moveUp();
				}

			} else {
				++scroll.fired;
			}

			scroll.lastDelta = event.deltaY;
		}

		// Handles touch events
		const touchStartHandler = (event) => {
			lastX = event.touches[0].clientX;
			lastY = event.touches[0].clientY;
		}

		const touchScrollHandler = (event) => {

			let dX = event.touches[0].clientX - lastX;
			let dY = event.touches[0].clientY - lastY;

			if (Math.abs(dX) > Math.abs(dY)) {
				return;
			}

			if (event.cancelable) event.preventDefault();
			if (scroll.is) {
				return;
			}

			if (dY < -5) {
				lastX = event.touches[0].clientX;
				lastY = event.touches[0].clientY;
				moveDown();
			} else if (dY > 5) {
				lastX = event.touches[0].clientX;
				lastY = event.touches[0].clientY;
				moveUp();
			}
		}


		// Handles key events
		const keyScrollHandler = (event) => {

			// let key = event.key;
			let key;
			if (!key) {
				key = codeToKey[event.keyCode];
			}

			if (key) {
				event.preventDefault();
				if (scroll.is) return;

				switch (key) {
					case 'Home':
						if (scroll.index > 1) scroll.index = 1;
						moveUp();
						break;
					case 'PageUp': moveUp(); break;
					case 'ArrowUp': moveUp(); break;

					case 'End':
						if (scroll.index < sections.length - 2) scroll.index = sections.length - 2;
						moveDown();
						break;
					case 'PageDown': moveDown(); break;
					case 'ArrowDown': moveDown(); break;

					default:
				}
			}
		}


		// Create an observer for checking current position
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5,
		};

		const callback = function (entries, observer) {
			entries.forEach(observed => {
				if (observed.isIntersecting) {
					scroll.index = observed.target.positionIndex;
				}
			});
		}

		const observer = new IntersectionObserver(callback, options);


		// Add all event listeners and section observer
		const prepareScroll = () => {
			sections = document.querySelectorAll('#main > section, #footer');
			if (!sections.length) return;

			window.addEventListener('wheel', wheelScrollHandler, { passive: false });
			window.addEventListener('touchstart', touchStartHandler, { passive: false });
			window.addEventListener('touchmove', touchScrollHandler, { passive: false });
			document.addEventListener('keydown', keyScrollHandler);

			sections.forEach((section, index) => {
				section.positionIndex = index;
				observer.observe(section);
			});
		}

		let timoutId = setTimeout(prepareScroll, 200);


		// Cleanup
		return () => {
			if (timoutId) clearTimeout(timoutId);
			if (typeof animation?.kill === 'function') animation.kill();
			window.removeEventListener('touchstart', touchStartHandler, { passive: false });
			window.removeEventListener('touchmove', touchScrollHandler, { passive: false });
			window.removeEventListener('wheel', wheelScrollHandler, { passive: false });
			document.removeEventListener('keydown', keyScrollHandler);
			sections?.forEach(section => observer.unobserve(section));
		}
	}, [data, isMobile]);


	// Scrolls to hash if hash is set
	useEffect(() => {
		let animation;
		setTimeout(() => { animation = scrollToHash(); }, timeOutHashLink);

		return () => {
			if (typeof animation?.kill === 'function') animation.kill();
		}
	}, [state, scrollToHash]);


	useEffect(() => {
		let cleanUp = [];
		let aniTex = document.querySelectorAll('.text-slide-up');
		if (aniTex && aniTex.length) {
			// console.log('preparing animation', (Date.now() - ttt));
			aniTex.forEach(elem => {
				animatedText(elem).then(res => {
					if(res) cleanUp.push(res);
					// console.log('prep done', (Date.now() - ttt));
				});
			});
		}
		return () => {
			cleanUp.forEach((aniTex) => {
				aniTex?.timeline?.kill();
				aniTex?.trigger?.kill();
			});
		}
	}, [nav]);


	return <main id="main" className="home">
		{data?.map((content) => {
			switch (content._type) {
				case 'hero': return <Hero content={content} key={content._key} />
				case 'list': return <List content={content} key={content._key} />
				case 'textImage': return <TextImage content={content} key={content._key} />
				case 'lottery': return <Lottery content={content} key={content._key} />
				default: return null;
			}
		})}
	</main>;
}