import RuIcon from '../images/TTW_Russia.svg';
import BrIcon from '../images/TTW_Brazil.svg';
import TrIcon from '../images/TTW_Turkey.svg';

const icons = {
	br: BrIcon,
	tr: TrIcon,
	ru: RuIcon
}

/**
 * Returns the TTW logo
 * @param {string} country Which logo
 * @returns {string} logo
 */
export default function TTWLogo (country) {
	return icons[country] || null;
} 