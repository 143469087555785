import React, { useEffect, useState } from "react";
import YouTube from 'react-youtube';

export default function HeroYoutube ({embedVideo, closeFullscreen}) {

	const [player, setPlayer] = useState(null);

	let youtubeOpts = null;

	let width = window.innerWidth;
	let height = window.innerHeight;


	// Prepare Youtube player
	if (embedVideo?.service === 'youtube') {

		if (embedVideo.width && embedVideo.height) {
			let dw = width / embedVideo.width;
			let dh = height / embedVideo.height;

			if(dw > dh){
				width = embedVideo.width * dh;
			} else {
				height = embedVideo.height * dw;
			}
		}

		youtubeOpts = {
			height,
			width,
			host: 'https://www.youtube-nocookie.com',
			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				// autoplay: 1,
				controls: 1,
				// loop: 1,
				// mute: 1,
				// playlist: embedVideo.id,
				// autohide: 1,
				playsinline: 1
			}
		};
	}

	/** Sets player as soon as it's ready */
	const _onReady = (event) => {
		// console.log('onReady', event);
		// access to player in all event handlers via event.target
		setPlayer(event.target);
	}

	/** Hides poster image when video starts playing */
	const _onStateChange = (event) => {
		// console.log('onStateChange', event);
		if (event.target.isVideoInfoVisible()) event.target.hideVideoInfo();
		if(event.data === 0 && closeFullscreen) closeFullscreen();
	}

	useEffect(()=>{
		if(!player) return;

		const startVideo = () => {
			if(player.isMuted()) player.unMute();
			player.playVideo();
		}

		const stopVideo = () => {
			player?.stopVideo();
		}

		const iframe = player.getIframe();

		const onResize = () => {
			let height = window.innerHeight;
			iframe.style.height = height + 'px';
		}
		document.addEventListener('hero:start-video', startVideo);
		document.addEventListener('hero:stop-video', stopVideo);


		window.addEventListener('resize', onResize);
		onResize();

		return () => {
			document.removeEventListener('hero:start-video', startVideo);
			document.removeEventListener('hero:stop-video', stopVideo);
			window.removeEventListener('resize', onResize);
		}
	}, [player]);


	if(!embedVideo) return null;

	return <div className="section-hero__iframe-adjustment">
		<YouTube
			containerClassName="section-hero__iframe-wrapper"
			className="section-hero__iframe"
			videoId={embedVideo.id}
			opts={youtubeOpts}
			onReady={_onReady}
			onStateChange={_onStateChange} />
	</div>
}