import React from "react";
import logo from '../../images/Twitter-red.svg';

export default function TextImageCensorship ({censorship}) {

	return <div className="section-text-image__censorship">

		<svg className="section-text-image__censorship-avatar" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="32.2438" cy="32.0276" r="31.5" stroke="currentColor"/>
			<path d="M22 34.8C17.6 28.4 24 14 32.5 14C41 14 47.4 28.4 43 34.8C39.8 41.1 25.2 41.1 22 34.8Z" stroke="currentColor"/>
			<path d="M12 56C23 40 42 40 53 56" stroke="currentColor"/>
		</svg>

		<img className="section-text-image__censorship-logo" 
			width="41" 
			height="33" 
			src={logo} 
			alt="Twitter Logo" />

		<div className="section-text-image__censorship-message">
			<div className="section-text-image__censorship-line left" />
			<div className="section-text-image__censorship-line right" />
			{ censorship && <h4>{censorship}</h4> }
		</div>
	</div>;
}