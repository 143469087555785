
/**
 * Sets language, alternative link, canonical link in the head
 * @param {string} path 
 * @param {string} lang 
 * @param {string[]} allLang 
 * @param {string} defaultLang 
 * @returns 
 */
export default function setHeat(path, lang = 'en', allLang = [], defaultLang = 'en') {

	if(!lang || !allLang || !allLang.length) return;

	// Remove old 
	let oldAlternative = document.querySelectorAll('link[rel="alternative"]');
	if(oldAlternative.length) oldAlternative.forEach(e => e.remove());

	let canonical = document.querySelector('link[rel="canonical"]');
	if(canonical) canonical.remove();

	const head = document.getElementsByTagName('head')[0];
	const host = window.location.protocol + '//' + window.location.host + '/';
	path = '/' + path;

	if(path !== '/faq') {
		allLang.forEach(l => createAlternativeLink(l, host + l + path, head) );
		createAlternativeLink('x-default', host + defaultLang + path, head);
	}
	createCanonical(host + lang + path, head);
}


// <link rel="alternate" hreflang="de" href="https://www.libri.de/de/fuer-den-handel/"></link>
/**
 * Create an alternative link
 * @param {string} lang 
 * @param {string} href 
 * @param {HTMLHeadElement} head 
 */
function createAlternativeLink (lang, href, head){
	let link = document.createElement('link');
	link.rel = 'alternative';
	link.hreflang = lang;
	link.href = href;
	head.appendChild(link);
}


// <link rel="canonical" href="https://www.libri.de/de/fuer-den-handel/">
/**
 * Creates a canonical link
 * @param {string} href
 * @param {HTMLHeadElement} head
 */
function createCanonical (href, head){
	let link = document.createElement('link');
	link.rel = 'canonical';
	link.href = href;
	head.appendChild(link);
}