import React from "react";
import { getImageUrl } from "../api/sanity";
import { getImageSrcSet } from "../helper/image";

export default function BlockImage ({image}){

	if(!image) return null;

	return <div className="article-blocks__image">
		<img src={getImageUrl(image).width(768).url()}
			srcSet={getImageSrcSet(image)}
			sizes='(min-width: 768px) 50vw, 100vw'
			alt="" />
	</div>
}