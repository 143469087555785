import React, { useContext, useEffect } from "react";
import LotteryBall from "./LotteryBall";
import BehindBars from "../BehindBars/BehindBars";
import { LanguageContext } from "../../globalStates/Language";
import useUpdateNav from "../../globalStates/updateNav";
import { getImageSrcSet } from "../../helper/image";
import TTWLogo from "../../helper/TTWLogo";
import scrollX from "../../helper/scroll-x";
import searchIcon from "../../images/search.svg";
import twitterOutline from "../../images/Twitter-Outline.svg";
import twitterRed from "../../images/Twitter-red.svg";
import lotteryNumber from "../../images/lottery_number.gif";
import { getImageUrl } from "../../api/sanity";
import './lottery.css';

export default function Lottery({ content }) {
	// console.log('lottery', content);

	const { translation } = useContext(LanguageContext);
	const { updateNav } = useUpdateNav();
	const balls = ['13', '35', '24', '23', '01', '25'];

	useEffect(() => {
		let list = document.querySelector('.section-lottery__tweets');
		let removeListener = scrollX(list);
		return () => {
			if(removeListener) removeListener();
		}
	}, [content])

	if (!content) return null;

	return <>
		<section id={content.anchor} className="section-lottery mode-light lottery-1" ref={updateNav} >
			<BehindBars />
			<div className="section-lottery__inner">
				<p className="section-lottery__before_heading text-slide-up">{content.before}</p>
				<h1 className="section-lottery__heading text-slide-up">{content.heading}</h1>
				<p className="section-lottery__description text-slide-up">
					<span className="">{content.description}</span>
					<span className="section-lottery__marking">
						<span className="section-lottery__marked">
							{content.marked}
						</span>
					</span>
				</p>
			</div>
		</section>

		<section className="section-lottery mode-light lottery-2" >
			<BehindBars />
			<div className="section-lottery__inner">
				<div>
					<h2 className="section-lottery__subheading text-slide-up">{content.subheading}</h2>
					<div className="section-lottery__text">
						<p className="section-lottery__order text-slide-up">01</p>
						<p className="section-lottery__text-1 text-slide-up">{content.text1}</p>
					</div>
				</div>
				<div className="section-lottery__balls">
					{ 
						balls.map((ball, index) => {
							return <div className="ball" key={ball}>
								<LotteryBall index={index} />
							</div>
						})
					}
				</div>
			</div>
		</section>

		<section className="section-lottery mode-light lottery-3" >
			<BehindBars />
			<div className="section-lottery__inner">
				<div>
					<h2 className="section-lottery__subheading text-slide-up">{content.subheading}</h2>
					<div className="section-lottery__text">
						<p className="section-lottery__order text-slide-up">02</p>
						<p className="section-lottery__text-2 text-slide-up">{content.text2}</p>
					</div>
				</div>
				<div className="section-lottery__numbers">
					<img className="section-lottery__search-icon" src={searchIcon} alt="" />
					{/* <p>230518422109</p> */}
					<img className="section-lottery__animated-lottery" src={lotteryNumber} alt="" />
					<img className="section-lottery__twitter-outline" src={twitterOutline} alt="" />
				</div>
			</div>
		</section>

		<section className="section-lottery mode-light lottery-4" >
			<BehindBars />
			<div className="section-lottery__inner">
				<div>
					<h2 className="section-lottery__subheading text-slide-up">{content.subheading}</h2>
					<div className="section-lottery__text">
						<p className="section-lottery__order text-slide-up">03</p>
						<p className="section-lottery__text-3 text-slide-up">{content.text3}</p>
					</div>
				</div>
			</div>
			<div className="section-lottery__tweets">
					{ content.tweets && content.tweets.map((tweet, index) => {
						let hasImage = tweet.image ? ' has-image' : '';
						
						return <div className={"section-lottery__tweet" + hasImage} key={index}>
							<div className="section-lottery__tweet-inner">
								<div className="section-lottery__tweet-top">
									<img className="section-lottery__tweet-rsf" src={TTWLogo(tweet.country)} alt="" />
									<div className="section-lottery__tweet-heading">{translation.lottery.tweet}: {translation.country[tweet.country]}</div>
									<img className="section-lottery__tweet-twitter" src={twitterRed} alt="" />
								</div>
								<div className="section-lottery__tweet-message">
									<span>{tweet.message}</span>
									<span className="section-lottery__tweet-hashtags"> {tweet.hashtags} </span>
									<span>{tweet.number}</span>
								</div>
								{tweet.image &&
									<div className="section-lottery__tweet-image">
										<img alt=""
											src={getImageUrl(tweet.image).width(403).url()}
											srcSet={getImageSrcSet(tweet.image)}
											sizes="(min-width: 768px) 403px, 248px" />
									</div>
								}
							</div>
						</div>
					})}
				</div>
		</section>
	</>
}