import React, { useContext } from "react";
import ListDownload from "./ListDownload";
import useIsMobile from "../../globalStates/isMobile";
import { GlobalContext } from "../../globalStates/globalData";
import useUpdateNav from "../../globalStates/updateNav";
import "./list.css";
import ListSocial from "./ListSocial";
import ListCountry from "./ListCountry";


export default function List(props) {
	// console.log('List', props);

	const content = props.content ?? {};
	const { globalData } = useContext(GlobalContext);
	const { isMobile } = useIsMobile();
	const lastColClass = isMobile ? 'ttw-button' : '';
	const { updateNav } = useUpdateNav();

	const getList = () => {
		switch (content.type) {
			case 'countries':

				if (globalData?.countries) {
					return globalData.countries.map((country, index) =>
						<ListCountry country={country} key={index} lastColClass={lastColClass} />
					)
				}
				break;

			case 'social':
				if (content.social) {
					return content.social.map((social, index) =>
						<ListSocial social={social} key={index} />
					)
				}
				break;

			case 'download':
				if (content.download) {
					return content.download.map((download, index) =>
						<ListDownload download={download} key={index} lastColClass={lastColClass} />
					)
				}
				break;

			default: return null;
		}
		return null;
	}

	return <section id={content.anchor} className={"section-list mode-light type-" + content.type} ref={updateNav}>
		<div className="section-list__inner">

			{
				content.heading &&
				<h2 className="section-list__heading text-slide-up">{content.heading}</h2>
			}

			<ul className="section-list__list" >
				{getList()}
			</ul>

		</div>
	</section>
}