import React from "react";
import useListMouseHandler from "../../animations/listMouseHandler";
import { getImageUrl } from "../../api/sanity";
import { getImageSrcSet } from "../../helper/image";
import twitterSvg from "../../images/Twitter-red.svg";
import instagramSvg from '../../images/Instagram_icon.svg';
import blockchainSvg from '../../images/Blockchain_Icon_02.svg';

export default function ListSocial({ social }) {

	const [liRef, imgRef] = useListMouseHandler();

	const getImage = () => {
		switch(social.type){
			case 'instagram': return instagramSvg;
			case 'blockchain': return blockchainSvg;
			default: return twitterSvg;
		}
	}

	if (!social) return null;

	return <li>
		{ 
			social.image &&
			<div className="section-list_entry-image for-desktop" ref={imgRef}>
				<img
					src={getImageUrl(social.image).width(768).url()}
					srcSet={getImageSrcSet(social.image)}
					sizes="(min-width: 768px) 287px, 100w"
					alt="" />
			</div>
		}

		<a className="section-list__entry" href={social.link?.link} traget={social.link?.target} ref={liRef}  rel='noopener noreferrer'>
			<p className={"section-list__entry-label"}>{social.link?.title}</p>
			<img className="section_list__entry-last-col" src={getImage()} alt="Twitter Logo" />
		</a>
	</li>
}