const translations = {
	en: {
		cookie: {
			message: 'The Truth Wins uses cookies to offer you a better experience. For more details, see ',
			privacy: 'privacy policy',
			accept: 'Accept',
			decline: 'Decline'
		},
		country: {
			br: 'Brazil',
			tr: 'Turkey',
			ru: 'Russia'
		},
		discover: {
			button: 'Discover',
			heading: 'Discover the truth',
			subHeading: 'The Truth Wins',
			twitter: 'Discover on Twitter',
			default: 'Uncensored Info',
			close: 'close'
		},
		footer: {
			top: 'Back to top'
		},
		hero: {
			scroll: 'Scroll',
			play: 'Play Film',
			close: 'Close'
		},
		list : {
			more: 'More information',
			subscribe: 'Subscribe',
		},
		lottery: {
			tweet: 'The Truth Wins'
		},
		textImage: {
			different: '€ different amount',
			donate: {
				before: 'donate ',
				after: ' €'
			}
		},
		singleCountry: {
			back: 'Back',
			country: 'Country',
			discover: 'Discover',
			lottery: 'Current lottery numbers',
			rank: '{c} press freedom index rank:',
			rankOf: 'out of 180 countries'
		}
	},

	de: {
		cookie: {
			message: 'The Truth Wins verwendet Cookies, um Ihnen ein besseres Erlebnis zu bieten. Für mehr Details, siehe ',
			privacy: 'Datenschutzerklärung',
			accept: 'Annehmen',
			decline: 'Ablehnen'
		},
		country: {
			br: 'Brasilien',
			tr: 'Türkei',
			ru: 'Russland'
		},
		discover: {
			button: 'Entdecken',
			heading: 'Entdecken:',
			subHeading: 'The Truth Wins',
			twitter: 'Auf Twitter',
			default: 'Unzensierte Infomationen',
			close: 'Schließen'
		},
		footer: {
			top: 'Nach oben'
		},
		hero: {
			scroll: 'Scrollen',
			play: 'Film abspielen',
			close: 'Schließen'
		},
		list : {
			more: 'Mehr Informationen',
			subscribe: 'Subscribe',
		},
		lottery: {
			tweet: 'The Truth Wins'
		},
		textImage: {
			different: '€ Anderer Betrag',
			donate: {
				before: '',
				after: ' € spenden'
			}
		},
		singleCountry: {
			back: 'Zurück',
			country: 'Land',
			discover: 'Entdecken',
			lottery: 'Aktuelle Lottozahlen',
			rank: 'In der Rangliste der Pressefreiheit belegt {c} Platz:',
			rankOf: 'von 180 Ländern'
		}
	}
}

export default translations;