import React, { useEffect, useContext, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Country from "./pages/Country";

import Header from "./components/Header/Header";
import Footer from "./components/Footer";

import { LanguageContext, LanguageProvider } from "./globalStates/Language";
import { GlobalContext, GlobalProvider } from "./globalStates/globalData";
import { loadDefaultData } from "./api/sanity";
import ResizeDetector from "./components/ResizeDetector";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import SimplePage from "./pages/SimplePage";
import CookieBanner from "./components/CookieBanner";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function Content() {
  const { setGlobalData } = useContext(GlobalContext);
  const { language } = useContext(LanguageContext);

  const sectionRefs = useRef([]);
  sectionRefs.current = [];

  useEffect(() => {
    loadDefaultData(language)
      // .then(r => {console.log(r); return r})
      .then(setGlobalData)
      .catch(console.error);
  }, [setGlobalData, language]);

  return <div id="animated-content">
    <ResizeDetector />
    <Header />
    <Routes>

      <Route element={<Home />} path="en/" />
      <Route element={<Country />} path='en/country/:slug' />
      <Route element={<SimplePage slug="imprint" className="page-imprint" />} path="en/imprint" />
      <Route element={<SimplePage slug="privacy" className="page-privacy" />} path="en/privacy" />
      <Route element={<SimplePage slug="faq" className="page-faq" backTo="/" />} path="en/faq" />

      <Route element={<Home />} path="de/" />
      <Route element={<Country />} path='de/country/:slug' />
      <Route element={<SimplePage slug="imprint" className="page-imprint" />} path="de/imprint" />
      <Route element={<SimplePage slug="privacy" className="page-privacy" />} path="de/privacy" />

    </Routes>
    <Footer />
    <CookieBanner />
  </div>
}

function App() {
  return <BrowserRouter>
    <LanguageProvider>
      <GlobalProvider>
        <Content />
      </GlobalProvider>
    </LanguageProvider>
  </BrowserRouter>;
}

export default App;