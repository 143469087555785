import React from "react";
import useListMouseHandler from "../../animations/listMouseHandler";
import { getImageUrl } from "../../api/sanity";
import { getImageSrcSet } from "../../helper/image";

export default function ListDownload({ download, lastColClass }) {
	const [liRef, imgRef] = useListMouseHandler();

	if (!download) return null;

	const labelClass = download.label2 ? 'w-15' : '';

	const getLinkParams = () => {
		if (download.fileName){
			return { href: process.env.PUBLIC_URL + '/files/' + download.fileName, download: true };
		}

		return { href: (download.link || '#x'), rel: 'noopener noreferrer', traget: '_blank'};
	}

	return <li>
		{download.image &&
			<div className="section-list_entry-image for-desktop" ref={imgRef}>
				<img alt=""
					src={getImageUrl(download.image).width(768).url()}
					srcSet={getImageSrcSet(download.image)}
					sizes="(min-width: 768px) 251px, 100w" />
			</div>
		}

		<a className="section-list__entry" {...getLinkParams()} ref={liRef}>
			<p className={"section-list__entry-label " + labelClass}>{download.label}</p>

			{download.image &&
				<div className="section-list_entry-image for-mobile">
					<img alt=""
						src={getImageUrl(download.image).width(768).url()}
						srcSet={getImageSrcSet(download.image)}
						sizes="(min-width: 768px) 251px, 100w" />
				</div>
			}

			{
				download.label2 &&
				<p className="section-list__entry-label label-2">{download.label2}</p>
			}

			<p className={"section_list__entry-last-col " + lastColClass}>{download.label3}</p>
		</a>
	</li>
}