import React, { useContext, useRef, useState } from "react";
import { LanguageContext } from "../../globalStates/Language";
import xIcon from '../../images/X.svg';

export default function HeroOverlay(props) {
	const [mouseClass, setMouseClass] = useState('');
	const { translation } = useContext(LanguageContext);
	const cursor = useRef();

	let isMouse = true;

	let _onTouchStart = (event) => {
		isMouse = false;
	}

	let _onMouseEnter = (event) => {
		if (isMouse) setMouseClass('moved');
	}

	let _onMouseLeave = (event) => {
		if (isMouse) setMouseClass('moved hide');
	}

	let _onMouseMove = (event) => {
		if (isMouse) {
			const { pageX, pageY } = event;
			cursor.current.style.left = pageX + 'px';
			cursor.current.style.top = pageY + 'px';
		}
	}
	let _onClickOverlay = () => {
		isMouse = true;
	}

	let _onClickPlay = (event) => {
		if (props.onClick) props.onClick(event);
		isMouse = true;
	}

	// let cursorText = props.heroClass && cursor.current?.classList.contains('moved') ? translation.hero.close : translation.hero.play;
	let cursorText = translation.hero.play;

	return <div className="section-hero__overlay"
		onTouchStart={_onTouchStart}
		onMouseEnter={_onMouseEnter}
		onMouseMove={_onMouseMove}
		onMouseLeave={_onMouseLeave}
		onClick={_onClickOverlay}>

		<div className={"section-hero__cursor " + mouseClass} ref={cursor} onClick={_onClickPlay}>{cursorText}</div>
		<button className="section-hero__menu-close" onClick={_onClickPlay}>
			<img src={xIcon} alt="" />
		</button>
	</div>
}