import React from "react";
import useIsMobile from "../../globalStates/isMobile";
import './behindBars.css';

export default function BehindBars () {

	const { isMobile } = useIsMobile();

	const getBars = () => {
		let bars = [];
		let i = isMobile ? 4 : 9;
		while(i--){
			bars.push(<div className="bar" key={i} />);
		}
		return bars;
	}

	return <div className="behind-bars">
		{ getBars() }
	</div>
}