import React from "react";
import { useLocation } from "react-router-dom";
import Hyperlink from "../Hyperlink";

export default function HeaderLanguage ({className, divider, onClick}) {

	const { pathname } = useLocation();
	const path = pathname.split("/");
	let href = "/";
	if( path.length > 1 && path[1].length !== 2){
		href = pathname;
	} else {
		href += path.slice(2).join('/');
	}

	const _onClick = () => {
		if(onClick) onClick();
	}
	
	return <p className={className} onClick={_onClick}>
		<Hyperlink to={href} claasName="lang" lang="de">DE</Hyperlink> {divider} <Hyperlink to={href} claasName="lang" lang="en">EN</Hyperlink>
	</p>
}