import logoDe from "../images/RSF_de.svg";
import logoEn from "../images/RSF_en.svg";

/**
 * Returns the RSF logo
 * @param {string} language Which logo
 * @returns {string} logo
 */
export default function RSFLogo (language = 'en') {
	if( language === 'de') return logoDe;
	else return logoEn;
}