import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const prepateTimeout = 200;

/**
 * Animate text by sliding up line by line
 * @param {Element} element 
 * @param {number} time 
 */
export default async function animatedText(element) {
	let targets = [];
	element._origin = element.innerHTML;

	// Get the Lines for the animation
	if(element.classList.contains('section-lottery__description')){
		// Because in this section their is a span with background-color
		let t = await Promise.all([getLines(element.children[0]), getLines(element.children[1])]);
		targets = [...t[0], ...t[1]];
	} else {
		targets = await getLines(element);
	}

	if(!targets.length) return false;

	let timeline = gsap.timeline( { 
		paused: true, 
		onComplete: recoverText, 
		onCompleteParams: [element] 
	});

	timeline.from(targets, {
		yPercent: 100,
		ease: 'sine.out',
		duration: 0.5,
		stagger: 0.1,
		delay: 0.2
	});

	let trigger = ScrollTrigger.create({
		trigger: element,
		onEnter: () => timeline.play(0),
		once: true,
		start: 'center bottom'
	});

	element._timeline = timeline;
	element._trigger = trigger;

	return { timeline, trigger };
}

async function getLines(element){

	await prepareLines(element);

	if(element.classList.contains('section-lottery__marking')){ // Only for the red Line
		splitLines(element, '<div class="tsu-parent"><div class="tsu-child"><span class="section-lottery__marked">', '</span></div></div>');
	} else {
		splitLines(element, '<div class="tsu-parent"><div class="tsu-child">', '</div></div>');
	}
	

	let targets = [];
	Array.from(element.children).forEach(parent => {
		let child = parent.children[0];
		if(child.innerHTML === ' '){ 
			child.remove();
		} else {
			targets.push(child);
		}
	});

	return targets;
}

function prepareLines (container) {
	return new Promise((resolve) => {
		// Put spans on each word, for now we use the <n> tag because, we want to save 5 bytes:)
		let tmp = container.innerText.replace(/[\S\n]+/g, '<n>$&</n>');
		container.innerHTML = tmp.replace(/[\n-]/g, '$&</n><n>');
		setTimeout(resolve, prepateTimeout);
	});
}

function splitLines(container, openingtag, closingtag) {

	var spans = container.children,
		top = 0,
		tmp = '',
		response = '';

	// Loop through each words (spans) 
	for (let i = 0; i < spans.length; i++) {

		// Get the height of each word
		var rect = spans[i].getBoundingClientRect().top;

		// Else if top is different as the last height of the word use a closingtag and use an opentag after that
		if (top < rect) {
			response += closingtag + openingtag;
		}

		top = rect;

		tmp = spans[i].textContent;

		// Add the spans + space between each word
		if(tmp.indexOf('-') !== -1 || tmp.indexOf('\n') !== -1) response += tmp;
		else response += tmp + ' ';
	}

	// Add the lines back to the paragraph 
	container.innerHTML = response += closingtag;
}

function recoverText (element) {
	element.innerHTML = element._origin;
	element._timeline?.kill();
	element._trigger?.kill();
	delete element._origin;
	delete element._timeline;
	delete element._trigger;
}