import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useUpdateNav from "../globalStates/updateNav";

export default function useNavScrollHandler() {
	const dark = useRef();
	const light = useRef();
	const { nav } = useUpdateNav();

	useEffect(() => {

		const sections = document.querySelectorAll('section.mode-light, section.mode-dark');
		if (!sections.length) return;

		let changes = getAllChanges(sections);
		if (!changes.length) return;

		let animations = [];

		setTimeout(() => {

			ScrollTrigger.create({
				trigger: sections[0],
				start: "top top",
				end: "bottom center",
				onEnter: (self) => { dark.current.classList.add('use-ttw'); },
				onEnterBack: (self) => { dark.current.classList.remove('use-ttw'); }
			});

			let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
			if (scrollPosition > 0) {
				dark.current.classList.add('use-ttw');
			}

			let height = dark.current.offsetHeight;
			changes.forEach((trigger) => {

				if (trigger.isDark) {
					animations.push(clipIn(dark.current, trigger, height));
					animations.push(clipOut(light.current, trigger, height));
				} else {
					animations.push(clipOut(dark.current, trigger, height));
					animations.push(clipIn(light.current, trigger, height));
				}

			});

			let i = 0;
			let len = sections.length;

			for(i; i < len; i++){
				let rect = sections[i].getBoundingClientRect();
				if( rect.y < 0 ) {
					if(i < len - 1) continue;
					if(sections[i].classList.contains('mode-dark')) setClipPath(dark.current, light.current, height);
					else setClipPath(light.current, dark.current, height);
					break;
				}

				let clip = 0;
				let isDark = true;
				if(i > 0){
					isDark = sections[i - 1].classList.contains('mode-dark');
					if(rect.y < height) {
						if( isDark !== sections[i].classList.contains('mode-dark')){
							clip = height - rect.y;
						}
					} else {
						clip = height;
					}
				}

				if(isDark) setClipPath(dark.current, light.current, height, clip);
				else setClipPath(light.current, dark.current, height, clip);
				break;
			}
			
		}, 200);

		return () => {
			animations.forEach(anim => {
				anim.kill();
			});
			ScrollTrigger.getAll().forEach(t => t.kill());
		};
	}, [nav]);

	return [dark, light];
}

function setClipPath (disappear, appear, height, clip = 0){
	let rest = height - clip;
	disappear.style.clipPath = `inset(0px 0px ${clip}px 0px)`;
	appear.style.clipPath = `inset(${rest}px 0px 0px 0px)`;
}

function getAllChanges(sections) {
	let isDark = false;
	if (sections[0].classList.contains('mode-dark')) isDark = true;

	let backChanged = [];
	for (let i = 0; i < sections.length; ++i) {
		if (isDark) {
			if (sections[i].classList.contains('mode-light')) {
				isDark = false;
				sections[i].isDark = false;
				backChanged.push(sections[i]);
			}
		} else {
			if (sections[i].classList.contains('mode-dark')) {
				isDark = true;
				sections[i].isDark = true;
				backChanged.push(sections[i]);
			}
		}
	}
	return backChanged;
}

function clipOut(target, trigger, height) {
	return gsap.fromTo(target, {
		clipPath: 'inset(0px 0px 0px 0px)',
		immediateRender: false
	},
		{
			scrollTrigger: {
				trigger: trigger,
				start: `-${height}px top`,
				end: "top top",
				scrub: true
			},
			clipPath: `inset(0px 0px ${height}px 0px)`,
			ease: 'none'
		});
}

function clipIn(target, trigger, height) {
	return gsap.fromTo(target, {
		clipPath: `inset(${height}px 0px 0px 0px)`,
		immediateRender: false
	},
		{
			scrollTrigger: {
				trigger,
				start: `-${height}px top`,
				end: "top top",
				scrub: true
			},
			clipPath: 'inset(0px 0px 0px 0px)',
			ease: 'none'
		});
}