import React, { useContext, useEffect, useState } from "react";
import { getPageBySlug } from "../api/sanity";
import BehindBars from "../components/BehindBars/BehindBars";
import BlockContent from "../components/BlockContent";
import Hyperlink from "../components/Hyperlink";
import { LanguageContext } from "../globalStates/Language";
import './simplePage.css';

export default function SimplePage({ slug, className = '', title, backTo }) {

	const [data, setData] = useState(null);
	const { language, translation } = useContext(LanguageContext);

	useEffect(() => {
		if (slug) {
			getPageBySlug(slug, language)
				// .then(r => { console.log('simple page:', slug, r); return r; })
				.then(setData)
				.catch(console.error);
		}
	}, [slug, language]);

	if (!data) return null;

	const blocks = [];
	let isUl = false;
	let i = 0;

	data.forEach(block => {
		if (block.listItem) {
			isUl = true;
			if (!blocks[i]) blocks[i] = { listItem: true, list: [] };
			block.style = 'li';
			blocks[i].list.push(block);
		} else {
			if (isUl) {
				++i
				isUl = false;
			};
			blocks[i] = block;
			++i;
		}
	});

	return <main id="main" className={"simple-page " + className}>
		<BehindBars />

		{
			backTo &&
			<div className="back-to">
				<Hyperlink to={backTo} className="back" >&lt; {translation.singleCountry.back}</Hyperlink>
			</div>
		}

		<div className="simple-page-inner">
			{
				title &&
				<h1>{title}</h1>
			}
			{
				blocks.map((block, index) => {
					if (block.listItem) {
						console.log(block);
						return <ul key={index}>
							{block.list.map((b,i) => <BlockContent className="content-block" block={b} key={i} />)}
						</ul>;
					}
					return <BlockContent className="content-block" block={block} key={index} />
				})
			}
		</div>
	</main>
}