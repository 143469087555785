import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import gsap from "gsap";
import { GlobalContext } from "../../globalStates/globalData";
import ttwLogoWhite from '../../images/TTW-White.svg';
import ttwLogoBlack from '../../images/TTW-Black.svg';
import Hyperlink from "../Hyperlink";


export default function HeaderMobile ({ mode }) {

	const { globalData } = useContext(GlobalContext);
	const content = globalData?.header;

	const { pathname } = useLocation();

	const isHome = pathname.length < 5;

	function menuOpen() {
		let menu = document.getElementById('header-mobile-menu');
		menu?.classList.add('open');
	}

	const getTTW = () => {
		switch (mode) {
			case 'mode-dark': return ttwLogoWhite;
			case 'mode-light': return ttwLogoBlack;
			default: return null;
		}
	}

	if (!content) return null;

	const onClickLogo = () => {
		gsap.to(window, { duration: 1.5, scrollTo: 0, ease: "sine.inOut" });
	}

	const getLogo = () => {
		if( isHome ) return (
			<div className="header-mobile__logo" onClick={onClickLogo}>
				<img src={getTTW()} alt="The Truth Wins Logo" />
			</div>
		);
		
		return (
			<Hyperlink className="header-mobile__logo" to="/">
				<img src={getTTW()} alt="The Truth Wins Logo" />
			</Hyperlink>
		);
	}

	return <>
		<button className="header-burger" onClick={menuOpen}>
			<div className="burger-line" />
			<div className="burger-line" />
			<div className="burger-line" />
		</button>
		{ getLogo() }
	</>
}