import { getImageUrl } from "../api/sanity";

export const getImageSrcSet = (source, sizes = [576, 768, 1024, 1440]) => {

	let i=0, l=sizes.length, srcSet = '';
	for(i; i < l; i++){
		srcSet += getImageUrl(source).width(sizes[i]).url();
		srcSet += ' ' + sizes[i] + 'w,';
	}
	return srcSet;
}