import React, { useContext } from "react";
import { GlobalContext } from "../globalStates/globalData";
import { LanguageContext } from "../globalStates/Language";

export default function BlockLottery ({country}){
	const { translation } = useContext(LanguageContext);
	const { globalData } = useContext(GlobalContext);

	if( !country || !globalData ) return null;

	return (<div className="article-blocks__lottery">
		<p className="article-blocks__lottery-label">{translation.singleCountry.lottery}</p>
		<p className="article-blocks__lottery-number">{globalData.lottery[country]}</p>
	</div>);
}