import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import HeaderLanguage from "./HeaderLanguage";
import Hyperlink from "../Hyperlink";
import { GlobalContext } from "../../globalStates/globalData";
import { LanguageContext } from "../../globalStates/Language";
import RSFLogo from "../../helper/RSFLogo";
import ttwLogoWhite from '../../images/TTW-White.svg';
import ttwLogoBlack from '../../images/TTW-Black.svg';
import gsap from "gsap";


export default function HeaderDesktop ({ mode }) {

	const { globalData } = useContext(GlobalContext);
	const { language } = useContext(LanguageContext);
	const content = globalData?.header;

	const { pathname } = useLocation();
	const isHome = pathname.length < 5;

	const rsfLogo = RSFLogo(language);

	const getTTW = () => {
		switch (mode) {
			case 'mode-dark': return ttwLogoWhite;
			case 'mode-light': return ttwLogoBlack;
			default: return null;
		}
	}

	const onClickLogo = () => {
		gsap.to(window, { duration: 1.5, scrollTo: 0, ease: "sine.inOut" });
	}

	const getLogo = () => {
		if( isHome ) return (
			<div className="header-desktop__logo" onClick={onClickLogo}>
				<img className="header-desktop__logo-rsf" src={rsfLogo} alt="RFS Logo" />
				<img className="header-desktop__logo-ttw" src={getTTW()} alt="The Truth Wins Logo" />
			</div>
		);
		
		return (
			<Hyperlink className="header-desktop__logo" to="/">
				<img className="header-desktop__logo-rsf" src={rsfLogo} alt="RFS Logo" />
				<img className="header-desktop__logo-ttw" src={getTTW()} alt="The Truth Wins Logo" />
			</Hyperlink>
		);
	}

	if (!content) return null;

	return <>
		{	getLogo() }
		
		{
			content.menu &&
			<nav className="header-desktop__menu">
				<ul>
					{
						content.menu.map((link) => {

							if (!link.title || !link.link) return null;

							return <li key={link._key}>
								<Hyperlink className="p" to={link.link} target={link.target} state={{ hashCheck: true }}>{link.title}</Hyperlink>
							</li>
						})
					}
				</ul>
			</nav>
		}
		<HeaderLanguage className="header-desktop__lang" />
	</>
}