import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import useIsMobile from "../globalStates/isMobile";


const movements = { x: 0.1, y: 0.5 };
let zIndex = 1;


export default function useListMouseHandler() {
	const { isMobile } = useIsMobile();
	const liRef = useRef();
	const imgRef = useRef();

	useEffect(() => {

		const wrapper = liRef.current;
		const img = imgRef.current;

		if (!wrapper || !img || isMobile) return;

		const pos = { x: 0, y: 0 };
		const mouse = { x: 0, y: 0 };
		const velocity = { x: [], y: [], t: [], i: 0 };

		const xSet = gsap.quickSetter(img, "x", "px");
		const ySet = gsap.quickSetter(img, "y", "px");

		let animation;

		function setDefaultVelocity(x, y, t) {
			let i = 5;
			while (i--) velocity.x[i] = x;
			i = 5;
			while (i--) velocity.y[i] = y;
			i = 5;
			while (i--) velocity.t[i] = t;
		}

		function trackVelocity(x, y, t) {
			velocity.x[velocity.i] = x;
			velocity.y[velocity.i] = y;
			velocity.t[velocity.i] = t;
			++velocity.i;
			if (velocity.i > 4) velocity.i = 0;
		}

		function getVelocity() {
			let o = velocity.i;
			let n = o;
			--n;
			if (n < 0) n = 4;

			let x = velocity.x[n] - velocity.x[o];
			let y = velocity.y[n] - velocity.y[o];
			let t = velocity.t[n] - velocity.t[o];

			x *= 100 / t;
			y *= 100 / t;

			return [x, y];
		}

		wrapper.addEventListener('mouseenter', mouseEnter);
		function mouseEnter(e) {
			mouse.x = e.offsetX * movements.x;
			mouse.y = e.offsetY * movements.y;
			setDefaultVelocity(mouse.x, mouse.y, e.timeStamp);
			// console.log('mouse enter', velocity);
			animate();
			img.style.opacity = 1;
			img.style.zIndex = zIndex++;
			animation?.kill();
			gsap.ticker.add(animate);
		}

		wrapper.addEventListener('mousemove', mouseMove);
		function mouseMove(e) {
			mouse.x = e.offsetX * movements.x;
			mouse.y = e.offsetY * movements.y;
			// console.log('mouse move', velocity);
			trackVelocity(mouse.x, mouse.y, e.timeStamp);
		}

		wrapper.addEventListener('mouseleave', mouseLeave);
		function mouseLeave(e) {
			gsap.ticker.remove(animate);
			let [x, y] = getVelocity();

			x += pos.x;
			y += pos.y;

			animation = gsap.to(img,
				{ x, y, opacity: 0, duration: 1 }
			);
		}

		const animate = () => {
			pos.x = mouse.x;
			pos.y = mouse.y;

			xSet(pos.x);
			ySet(pos.y);
		}

		return () => {
			wrapper.removeEventListener('mouseenter', mouseEnter);
			wrapper.removeEventListener('mousemove', mouseMove);
			wrapper.removeEventListener('mouseleave', mouseLeave);
			gsap.ticker.remove(animate);
			animation?.kill();
			zIndex = 1;
		}
	}, [isMobile]);

	return [liRef, imgRef];
}