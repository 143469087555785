import React, { useContext } from "react";
import useListMouseHandler from "../../animations/listMouseHandler";
import { getImageUrl } from "../../api/sanity";
import { LanguageContext } from "../../globalStates/Language";
import { getImageSrcSet } from "../../helper/image";
import Hyperlink from "../Hyperlink";

export default function ListCountry({ country, lastColClass }) {

	const { translation } = useContext(LanguageContext);
	const [liRef, imgRef] = useListMouseHandler();

	if (!country) return null;

	const href = '/country/' + country.slug;

	return <li>
		{
			country.listImage &&
			<div className="section-list_entry-image for-desktop" ref={imgRef}>
				<img
					src={getImageUrl(country.listImage).width(768).url()}
					srcSet={getImageSrcSet(country.listImage)}
					sizes="(min-width: 768px) 287px, 100w"
					alt="" />
			</div>
		}

		<Hyperlink className="section-list__entry hover-font-size" to={href} ref={liRef}>
			<p className="section-list__entry-label">{translation.country[country.country]}</p>
			{
				country.listImage &&
				<div className="section-list_entry-image for-mobile">
					<img
						src={getImageUrl(country.listImage).width(768).url()}
						srcSet={getImageSrcSet(country.listImage)}
						sizes="(min-width: 768px) 287px, 100w"
						alt="" />
				</div>
			}
			<p className={"section_list__entry-last-col " + lastColClass}>{translation.list.more}</p>
		</Hyperlink>
	</li>
}