import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../globalStates/Language";

const changePathToHome = {de: { "/faq": 1}, en: {}};

const Hyperlink = React.forwardRef(({ children, className, state, target, to, lang }, ref) => {
	const { languagePath } = useContext(LanguageContext);
	let linkProps = {};

	if(!to) return <span className={className} ref={ref}>{children}</span>;

	if(to.startsWith('/')) {

		let href = '';
		if(lang){
			if(changePathToHome[lang][to]){
				href = '/' + lang + "/";
			} else {
				href = '/' + lang + to;
			}
		} else href = languagePath + to;

		if(target && target === '_blank') linkProps = {rel: 'noopener noreferrer' };
		
		return <Link className={className} to={href} target={(target || "_self")} state={state} ref={ref} {...linkProps}>{children}</Link>
	}

	if(!target || target === '_blank') linkProps = {rel: 'noopener noreferrer' };
	return <a className={className} href={to} target={(target || "_blank")} ref={ref} {...linkProps}>{children}</a>
});

export default Hyperlink;