import React, { useContext } from "react";
import TextImageCensorship from "./TextImageCensorship";
import TextImageDonate from "./TextImageDonate";
import { getImageUrl } from "../../api/sanity";
import { getImageSrcSet } from "../../helper/image";
import { GlobalContext } from "../../globalStates/globalData";
import { LanguageContext } from "../../globalStates/Language";
import useIsMobile from "../../globalStates/isMobile";
import useUpdateNav from "../../globalStates/updateNav";
import './textImage.css';

export default function TextImage(props) {
	// console.log('text image', props);

	const { isMobile } = useIsMobile();
	const content = props.content || {};
	let type = content.type ?? 'text';

	const { updateNav } = useUpdateNav();
	const { globalData } = useContext(GlobalContext);
	const { translation } = useContext(LanguageContext);

	const getAnimatedLottery = () => {
		return <div className="section-text-image__lottery">
			<div className="loop-infinity">
				{getLotteryNumbers()}
				{getLotteryNumbers()}
			</div>
		</div>
	}

	const getLotteryNumbers = () => {
		if (!globalData || !globalData.countries || !globalData.countries.length) return null;
		return globalData.countries.map((country, index) => {
			return <div className="looping" key={index}>
				{translation.country[country.country]} {globalData.lottery[country.country]}
			</div>;
		});
	}

	return <section id={content.anchor} className={"section-text-image mode-dark type-" + type} ref={updateNav}>
		{
			content.background &&
			<img className="section-text-image__background"
				src={getImageUrl(content.background).width(1440).url()}
				srcSet={getImageSrcSet(content.background)}
				sizes="100w"
				alt="" />
		}
		{
			content.caption &&
			<p className="section-text-image__background-caption">
				{content.caption}
			</p>
		}

		<div className="section-text-image__inner">
			{
				isMobile && type === 'lottery' && getAnimatedLottery() }

			{
				!isMobile && type === 'censorship' && content.censorship &&
				<TextImageCensorship censorship={content.censorship} />
			}

			<div className="section-text-image__info">
				{
					content.heading &&
					<h3 className="section-text-image__heading text-slide-up">{content.heading}</h3>
				}

				{ !isMobile && type === 'lottery' && getAnimatedLottery() }

				{
					isMobile && type === 'censorship' && content.censorship &&
					<TextImageCensorship censorship={content.censorship} />
				}

				<div className="section-text-image__text-wrapper">
					<p className="section-text-image__text text-slide-up">{content.text}</p>
					{
						type !== 'donate' &&
						<p className="section-text-image__text text-slide-up">{content.text2}</p>
					}
				</div>

				{
					type === 'donate' &&
					<div>
						<TextImageDonate />
						<p className="section-text-image__text text-slide-up">{content.text2}</p>
						<p className="section-text-image__note text-slide-up">{content.note}</p>
					</div>
				}
			</div>
		</div>
	</section>;
}