import React, { useContext } from "react";
import { LanguageContext } from "../globalStates/Language";

export default function BlockIndex ({country, index}){
	const { translation } = useContext(LanguageContext);

	if(!index) return null;

	return (<div className="article-blocks__index-wrapper">
		<div className="article-blocks__index" >
			<p className="article-blocks__index-before">{translation.singleCountry.rank.replace('{c}', translation.country[country])}</p>
			<p className="article-blocks__index-number">{index}</p>
			<p className="article-blocks__index-after">{translation.singleCountry.rankOf}</p>
		</div>
	</div>);
}