import React, { useState, createContext } from "react";

const GlobalContext = createContext(null);

function GlobalProvider(props) {
	const [globalData, setGlobalData] = useState(null);

	return <GlobalContext.Provider value={{ globalData, setGlobalData }}>
		{props.children}
	</GlobalContext.Provider>
}

export {
	GlobalContext,
	GlobalProvider
}