import React from "react";
import { useLocation } from "react-router-dom";
import HeaderDiscover from "./HeaderDiscover";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import HeaderMobileMenu from "./HeaderMobileMenu";
import useIsTablet from "../../globalStates/isTablet";
import './header.css';
import useNavScrollHandler from "../../animations/navScrollHandler";


export default function Header() {
	const { isTablet } = useIsTablet();
	const { pathname } = useLocation();
	const [dark, light] = useNavScrollHandler();

	let darkClass = ' hidden';
	let lightClass = ' showing';

	if( pathname.length < 5){
		darkClass = '';
		lightClass = ' hide';
	}

	if (isTablet) return <header id="header">
			<div className={"header-mobile mode-dark" + darkClass} ref={dark}>
				<HeaderMobile mode="mode-dark" />
			</div>
			<div className={"header-mobile mode-light" + lightClass} ref={light}>
				<HeaderMobile mode="mode-light" />
			</div>

			<HeaderMobileMenu />
			<HeaderDiscover />
		</header>

	if(window.scrollY > window.innerHeight * 2) darkClass += " use-ttw";

	return <header id="header">
		<div className={"header-desktop mode-dark" + darkClass} ref={dark}>
			<HeaderDesktop mode="mode-dark" />
		</div>
		<div className={"header-desktop mode-light use-ttw" + lightClass} ref={light}>
			<HeaderDesktop mode="mode-light" />
		</div>
		<HeaderDiscover />
	</header>
}